import { getStore } from '../store/Store';
import { updateClipboardWritable } from '../mutator/updateClipboardWritable';
import { logUsage } from 'owa-analytics';

const isClipboardWritable = () => {
    return getStore().clipboardWritable;
};

(async () => {
    let permissionStatus: PermissionStatus | undefined = undefined;
    try {
        permissionStatus = await navigator?.permissions?.query?.({
            name: 'clipboard-write' as PermissionName,
            allowWithoutGesture: true,
        } as PermissionDescriptor);
    } catch (e) {
        logUsage('CnexErrorIsClipboardWritable', { error: e });
    }

    if (!permissionStatus) {
        updateClipboardWritable(false);
    } else {
        updateClipboardWritable(
            permissionStatus.state === 'granted' || permissionStatus.state === 'prompt'
        );

        permissionStatus.addEventListener('change', () => {
            updateClipboardWritable(
                permissionStatus.state === 'granted' || permissionStatus.state === 'prompt'
            );
        });
    }
})();

export default isClipboardWritable;
